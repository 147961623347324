import { Zakres } from "../features/features";
import { NavigationNew } from "../nowemenu/nowemenu";

export const Oferta = () => {
  return (
    <div>
      <NavigationNew />
      <Zakres />
    </div>
  );
};
